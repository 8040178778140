@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:regular,bold,italic&subset=latin,latin-ext);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.navbar-selected-tab {
    color: rgb(31, 149, 245) !important;
    font-weight: 500;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

h6 {
  font-size: 0.8vw
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.alert-container {
    position: absolute !important;
    bottom: 0 !important;
    left: 0 !important;
}

.refresh-button {
    position: absolute !important;
    top: 0;
    right: 0;
    margin: 20px;
}
.alert-container {
    position: absolute !important;
    bottom: 0 !important;
    left: 0 !important;
}

.refresh-button {
    position: absolute !important;
    top: 0;
    right: 0;
    margin: 20px;
}
.dot {
    height: 25px;
    width: 25px;
    background-color: black;
    border-radius: 50%;
    display: inline-block;
}

.dot-red {
    background-color: red;
}

.dot-yellow {
    background-color: yellow;
}

.dot-green {
    background-color: limegreen;
}
.main-card-style{
border: none;
}
/*body {*/
/*    background-color: #f5f7fb;*/
/*}*/


.modal-settings {
    width: 100% !important;
    top: 40% !important;
    position: absolute !important;
    right: 30% !important;
    margin-right: 5vh !important;
    z-index: 10000 !important;
    max-width: 800px !important;
}

.card-text-info{
    font-weight: bold;
    white-space: nowrap;
}
.sub-card-style{
    border: none;
    border-radius: 25px;
    height: 100%;
}
.sub-green-card-style{
    border: none;
    background-color: #6bee5f;

    border-radius: 25px;
}
.sub-yellow-card-style{
    border: none;
    background-color: #e6cc72;
    border-radius: 25px;
}
.sub-red-card-style{
    border: none;
    color: white;
    background-color: #DC354B;
    border-radius: 25px;
}
td {
    border-right: none !important;
    border-left: none !important;
    padding: 0.4rem 0 0 !important;
    vertical-align: middle !important;
}

table{
    border: none !important;
    padding: 0 !important;
}

.bg-green {
    background-color: #6BEE5F!important;
}
.bg-yellow {
    background-color: #e6cc72!important;
}

.bg-red {
    background-color: #de6261!important;
}

.greenNotificationBadge{
    padding-right: 5px;
    padding-left: 5px;
    border-radius: 25px;
    background-color: #87d423;
    color:#121c04;
}

.redNotificationBadge{
    vertical-align: center;
    text-align: center;
    padding-right: 1em;
    padding-left: 1em;
    border-radius: 25px;
    background-color: #de6261;
    color:#121c04;
}

.openAlertsBadge{
    vertical-align: center;
    text-align: center;
    padding-right: 1em;
    padding-left: 1em;
    border-radius: 25px;

    background-color: #3eb9ca;
    color:white;
}
.vl {
    border-left: 1px solid #E5E5E5;
    height: 80px;
}

p span{
    display: block;
}

.alertBadge{
    padding-right: 5px;
    padding-left: 5px;
    border-radius: 25px;
    background-color: #de6261;
    color:white;
}



.main-card-style {
    border: none;
}

/*body {*/
/*    background-color: #f5f7fb;*/
/*}*/


.modal-settings {
    width: 100% !important;
    top: 10% !important;
    position: absolute !important;
    right: 10% !important;
    margin-right: 5vh !important;
    z-index: 10000 !important;
    max-width:150vh !important;
}

.card-text-info {
    font-weight: bold;
    white-space: nowrap;
}

.sub-card-style {
    border: none;
    border-radius: 25px;
    height: 100%;
}

.sub-green-card-style {
    border: none;
    background-color: #63e550;
    border-radius: 25px;
    height: 100%;
}

.sub-yellow-card-style {
    border: none;
    background-color: #e6cc72;
    border-radius: 25px;
    height: 100%;
}

.sub-red-card-style {
    border: none;
    color: white;
    background-color: #DC354B;
    border-radius: 25px;
    height: 100%;
}

td {
    border-right: none !important;
    border-left: none !important;
    padding: 0.4rem 0 0 !important;
    vertical-align: middle !important;
}

table {
    border: none !important;
    padding: 0 !important;
}

.bg-green {
    background-color: #58c948 !important;
}

.bg-yellow {
    background-color: #e6cc72 !important;
}

.bg-red {
    background-color: #de6261 !important;
}

.greenNotificationBadge {
    padding-right: 5px;
    padding-left: 5px;
    border-radius: 25px;
    background-color: #87d423;
    color: #121c04;
}

.redNotificationBadge {
    vertical-align: center;
    text-align: center;
    padding-right: 1em;
    padding-left: 1em;
    border-radius: 25px;
    background-color: #de6261;
    color: #121c04;
}

.openAlertsBadge {
    vertical-align: center;
    text-align: center;
    padding-right: 1em;
    padding-left: 1em;
    border-radius: 25px;

    background-color: #3eb9ca;
    color: white;
}

.vl {
    border-left: 1px solid #E5E5E5;
    height: 80px;
}

p span {
    display: block;
}

.alertBadge {
    padding-right: 5px;
    padding-left: 5px;
    border-radius: 25px;
    background-color: #de6261;
    color: white;
}

.testimonial-group > .row {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
}

.testimonial-group > .row > .col-md-6 {
    display: inline-block;
    float: none;
}

.divider {
    border-right: 1px solid #333;
}

.filter_form {
    display: flex !important;
}

.filter_section {
    display: flex;
    flex-direction: row;
    margin-left: 35%;
}

.filer_checkbox {
    float: left;
    margin-left: 20%;
}

.offset_button {
    position: relative;
    background-color: transparent !important;
    border-color: transparent !important;
    color: black !important;
    margin-top: -5% !important;
}

.right_button {
    left: 45%;
}

.left_button {
    left: -45%;
}

.react-bootstrap-table-page-btns-ul {
    float: none;
}

.react-bootstrap-table-pagination-list {
    text-align: center;
    transform: translateX(-50%);
}

th .order-4:before {
    margin-left: 3.5px;
    content: "\2191";
    opacity: 0.4;
}

th .order-4:after {
    content: "\2193";
    opacity: 0.4;
}

th .caret-4-asc:before {
    margin-left: 3.5px;
    content: "\2191";
}

th .caret-4-asc:after {
    content: "\2193";
    opacity: 0.4;
}

th .caret-4-desc:before {
    margin-left: 3.5px;
    content: "\2191";
    opacity: 0.4;
}

th .caret-4-desc:after {
    content: "\2193";
}

.react-bootstrap-table > table > thead > tr > th > .number-filter > .filter-label {
    display: inline-block !important;
}

.react-bootstrap-table > table > thead > tr > th .filter-label {
    display: block !important;
}

.table th {
    padding: 0.20rem;
    vertical-align: text-top !important;
}

label {
    margin-bottom: 0;
}

.custom-dropdown .Dropdown-control {
    height: 35px;
}

.chart-container {
    height: 100%;
}

.chart-wrapper {
    flex: 1 1;
    min-width: 400px;
    max-width: 1000px;
    margin: 0;
    height: 250px
}

.chart-wrapper > div {
    height:100%;
}

.modal-size {
    max-width: 70vw;
    max-height: 50vh;
}

.modal-content {
    height: 100%;
}

.modal-body {
    display: flex;
    flex-direction: column;
}



/* width */
::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 2px grey;
    border-radius: 5px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #007bff;
    border-radius: 5px;
  }
.export-to-csv-button {
    position: absolute;
    left: 0;
    margin: 5px;
    z-index: 100;
    background-color: #309c27 !important;
}

.export-to-csv-button:hover {
    background-color: #49c23e !important;
}

.export-to-csv-button:active {
    background-color: #6bee5f !important;
}
.blinking{
    animation:blinking-text 0.6s;
}
@keyframes blinking-text{
    0%{     background-color: #45e670;    }
    100%{   background-color: #fff;    }
}
.weekly-trend-container {
    display: flex;
    flex-direction: column;
    height: 85vh;
}

.weekly-trend-wrapper {
    display: flex;
    flex-grow: 1;
}

.tab-nav {
    flex-shrink: 0;
    width: 500px;
    background-color: #f8f9fa;
    padding: 50px 0 0 0;
    border-right: 1px solid #ddd;
    font-family: 'Arial', sans-serif;
}

.tab-nav .nav-link {
    display: flex;
    align-items: center;
    padding: 25px 25px 25px 15px;
    color: #818080;
    border-radius: 8px;
    font-size: 16px;
    text-align: left;
    transition: all 0.3s ease;
    cursor: pointer;
}

.tab-nav .nav-link:not(.active):hover {
    color: #000000;
}

.tab-nav .nav-link.active {
    background-color: rgba(0, 0, 0, 0);
    color: #000000;
    background-color: #e4e4e4;
}

.tab-nav .nav-link.active:hover {
    color: #000000;
    background-color: #e4e4e4;
}

.weekly-trend-content {
    flex-grow: 1;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
}

.highcharts-container {
    width: 100%;
    height: 85vh;
}

