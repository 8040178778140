.modal-size {
    max-width: 70vw;
    max-height: 50vh;
}

.modal-content {
    height: 100%;
}

.modal-body {
    display: flex;
    flex-direction: column;
}
