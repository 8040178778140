.dot {
    height: 25px;
    width: 25px;
    background-color: black;
    border-radius: 50%;
    display: inline-block;
}

.dot-red {
    background-color: red;
}

.dot-yellow {
    background-color: yellow;
}

.dot-green {
    background-color: limegreen;
}