.chart-container {
    height: 100%;
}

.chart-wrapper {
    flex: 1;
    min-width: 400px;
    max-width: 1000px;
    margin: 0;
    height: 250px
}

.chart-wrapper > div {
    height:100%;
}
