.main-card-style {
    border: none;
}

/*body {*/
/*    background-color: #f5f7fb;*/
/*}*/


.modal-settings {
    width: 100% !important;
    top: 10% !important;
    position: absolute !important;
    right: 10% !important;
    margin-right: 5vh !important;
    z-index: 10000 !important;
    max-width:150vh !important;
}

.card-text-info {
    font-weight: bold;
    white-space: nowrap;
}

.sub-card-style {
    border: none;
    border-radius: 25px;
    height: 100%;
}

.sub-green-card-style {
    border: none;
    background-color: #63e550;
    border-radius: 25px;
    height: 100%;
}

.sub-yellow-card-style {
    border: none;
    background-color: #e6cc72;
    border-radius: 25px;
    height: 100%;
}

.sub-red-card-style {
    border: none;
    color: white;
    background-color: #DC354B;
    border-radius: 25px;
    height: 100%;
}

td {
    border-right: none !important;
    border-left: none !important;
    padding: 0.4rem 0 0 !important;
    vertical-align: middle !important;
}

table {
    border: none !important;
    padding: 0 !important;
}

.bg-green {
    background-color: #58c948 !important;
}

.bg-yellow {
    background-color: #e6cc72 !important;
}

.bg-red {
    background-color: #de6261 !important;
}

.greenNotificationBadge {
    padding-right: 5px;
    padding-left: 5px;
    border-radius: 25px;
    background-color: #87d423;
    color: #121c04;
}

.redNotificationBadge {
    vertical-align: center;
    text-align: center;
    padding-right: 1em;
    padding-left: 1em;
    border-radius: 25px;
    background-color: #de6261;
    color: #121c04;
}

.openAlertsBadge {
    vertical-align: center;
    text-align: center;
    padding-right: 1em;
    padding-left: 1em;
    border-radius: 25px;

    background-color: #3eb9ca;
    color: white;
}

.vl {
    border-left: 1px solid #E5E5E5;
    height: 80px;
}

p span {
    display: block;
}

.alertBadge {
    padding-right: 5px;
    padding-left: 5px;
    border-radius: 25px;
    background-color: #de6261;
    color: white;
}

.testimonial-group > .row {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
}

.testimonial-group > .row > .col-md-6 {
    display: inline-block;
    float: none;
}

.divider {
    border-right: 1px solid #333;
}

.filter_form {
    display: flex !important;
}

.filter_section {
    display: flex;
    flex-direction: row;
    margin-left: 35%;
}

.filer_checkbox {
    float: left;
    margin-left: 20%;
}

.offset_button {
    position: relative;
    background-color: transparent !important;
    border-color: transparent !important;
    color: black !important;
    margin-top: -5% !important;
}

.right_button {
    left: 45%;
}

.left_button {
    left: -45%;
}

.react-bootstrap-table-page-btns-ul {
    float: none;
}

.react-bootstrap-table-pagination-list {
    text-align: center;
    transform: translateX(-50%);
}

th .order-4:before {
    margin-left: 3.5px;
    content: "\2191";
    opacity: 0.4;
}

th .order-4:after {
    content: "\2193";
    opacity: 0.4;
}

th .caret-4-asc:before {
    margin-left: 3.5px;
    content: "\2191";
}

th .caret-4-asc:after {
    content: "\2193";
    opacity: 0.4;
}

th .caret-4-desc:before {
    margin-left: 3.5px;
    content: "\2191";
    opacity: 0.4;
}

th .caret-4-desc:after {
    content: "\2193";
}

.react-bootstrap-table > table > thead > tr > th > .number-filter > .filter-label {
    display: inline-block !important;
}

.react-bootstrap-table > table > thead > tr > th .filter-label {
    display: block !important;
}

.table th {
    padding: 0.20rem;
    vertical-align: text-top !important;
}

label {
    margin-bottom: 0;
}

.custom-dropdown .Dropdown-control {
    height: 35px;
}
