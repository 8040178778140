.weekly-trend-container {
    display: flex;
    flex-direction: column;
    height: 85vh;
}

.weekly-trend-wrapper {
    display: flex;
    flex-grow: 1;
}

.tab-nav {
    flex-shrink: 0;
    width: 500px;
    background-color: #f8f9fa;
    padding: 50px 0 0 0;
    border-right: 1px solid #ddd;
    font-family: 'Arial', sans-serif;
}

.tab-nav .nav-link {
    display: flex;
    align-items: center;
    padding: 25px 25px 25px 15px;
    color: #818080;
    border-radius: 8px;
    font-size: 16px;
    text-align: left;
    transition: all 0.3s ease;
    cursor: pointer;
}

.tab-nav .nav-link:not(.active):hover {
    color: #000000;
}

.tab-nav .nav-link.active {
    background-color: rgba(0, 0, 0, 0);
    color: #000000;
    background-color: #e4e4e4;
}

.tab-nav .nav-link.active:hover {
    color: #000000;
    background-color: #e4e4e4;
}

.weekly-trend-content {
    flex-grow: 1;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
}

.highcharts-container {
    width: 100%;
    height: 85vh;
}
