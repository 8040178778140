.main-card-style{
border: none;
}
/*body {*/
/*    background-color: #f5f7fb;*/
/*}*/


.modal-settings {
    width: 100% !important;
    top: 40% !important;
    position: absolute !important;
    right: 30% !important;
    margin-right: 5vh !important;
    z-index: 10000 !important;
    max-width: 800px !important;
}

.card-text-info{
    font-weight: bold;
    white-space: nowrap;
}
.sub-card-style{
    border: none;
    border-radius: 25px;
    height: 100%;
}
.sub-green-card-style{
    border: none;
    background-color: #6bee5f;

    border-radius: 25px;
}
.sub-yellow-card-style{
    border: none;
    background-color: #e6cc72;
    border-radius: 25px;
}
.sub-red-card-style{
    border: none;
    color: white;
    background-color: #DC354B;
    border-radius: 25px;
}
td {
    border-right: none !important;
    border-left: none !important;
    padding: 0.4rem 0 0 !important;
    vertical-align: middle !important;
}

table{
    border: none !important;
    padding: 0 !important;
}

.bg-green {
    background-color: #6BEE5F!important;
}
.bg-yellow {
    background-color: #e6cc72!important;
}

.bg-red {
    background-color: #de6261!important;
}

.greenNotificationBadge{
    padding-right: 5px;
    padding-left: 5px;
    border-radius: 25px;
    background-color: #87d423;
    color:#121c04;
}

.redNotificationBadge{
    vertical-align: center;
    text-align: center;
    padding-right: 1em;
    padding-left: 1em;
    border-radius: 25px;
    background-color: #de6261;
    color:#121c04;
}

.openAlertsBadge{
    vertical-align: center;
    text-align: center;
    padding-right: 1em;
    padding-left: 1em;
    border-radius: 25px;

    background-color: #3eb9ca;
    color:white;
}
.vl {
    border-left: 1px solid #E5E5E5;
    height: 80px;
}

p span{
    display: block;
}

.alertBadge{
    padding-right: 5px;
    padding-left: 5px;
    border-radius: 25px;
    background-color: #de6261;
    color:white;
}


