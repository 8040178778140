.alert-container {
    position: absolute !important;
    bottom: 0 !important;
    left: 0 !important;
}

.refresh-button {
    position: absolute !important;
    top: 0;
    right: 0;
    margin: 20px;
}