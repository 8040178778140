.export-to-csv-button {
    position: absolute;
    left: 0;
    margin: 5px;
    z-index: 100;
    background-color: #309c27 !important;
}

.export-to-csv-button:hover {
    background-color: #49c23e !important;
}

.export-to-csv-button:active {
    background-color: #6bee5f !important;
}